<template>
  <div class="main-page">
   <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
export default {
  name: "SecondaryPages",
}
</script>

<style scoped lang="scss">
@import "src/layout/MainPages/MainPage.scss";
.main-page{
  min-height: 100vh;
  padding: 20px 0;
  main{
    border-radius: 12px;
  }
}
@media (max-width: 1100px) {
  .main-page{
    margin: auto;
    min-height: 100vh;
    main{
      margin: 0;
    }
  }
}
</style>